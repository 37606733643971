<app-webnav *ngIf="!isMobileResolution" #webnavbarComponent (navbarButtonClick)="onWebLibraryNavbarBtnClicked($event)"></app-webnav>
<!-- <app-card-horizontal-slider *ngIf="userDataService.userReviewPublishRejectModels?.length > 0 && userDataService.userDraftCollections" [title]="'Published'" [sourceCards]="userDataService.userReviewPublishRejectModels" (cardClicked)="onPublishReviewRejectModelsCardClicked($event)" (titleClicked)="onSeeAllClicked()" ></app-card-horizontal-slider> -->

<ng-container *ngIf="webNavService.isUserAuthenticated">

<div *ngIf="weblibraryMode === LIBARY_MODE.DEFAULT" class="library-content-area" [@inOutAnimation]>
  <div class="new-user-wrapper" *ngIf="isNoDataShown() && userDataService.savedCollections?.length === 0">
    <h1 class="new-user-title">Your Portfolio right here</h1>
    <h3 class="new-user-desc">Tap Add to upload your model</h3>
  </div>

  <!-- <app-card-composite-slider class="upper-class" *ngIf="userDataService.userReviewPublishRejectModels?.length > 0 || userDataService.savedCollections?.length > 0" [publishRejectedReviewsCards]="userDataService.userReviewPublishRejectModels" [sourceCollections]="userDataService.savedCollections" (publishRejectedReviewsGalleryClicked)="onPublishReviewRejectGalleryClicked()" (savedCollectionClicked)="onSavedCollectionClicked($event)" [title]="" ></app-card-composite-slider> -->
  <app-thumbnail-gallery *ngIf="!isNoDataShown()" [notInCollection]="mockDataService.generateEmptyCollection(userDataService.allUserCards)" [sourceCollections]="userDataService.userDraftCollections" [savedCardCollection]="userDataService.savedCollections" [galleryTitle]="" (defaultCollectionClciked)="onPortfolioCollectionClciked()" (customBtnClicked)="onDefaultCollectionOrganizeBtnClick($event)" (collectionClicked)="onDraftCollectionClicked($event)" class="zero-margin last-slider-no-switch-panel" ></app-thumbnail-gallery>
</div>

<div *ngIf="weblibraryMode === LIBARY_MODE.PUBLISH_REJECTED_REVIEW"  class="library-content-area" >
  <ng-container *ngIf="webNavService.currentWebSwitchPanelMode === SWITCH_PANEL_ITEM.PUBLISHED">
     <app-thumbnail-gallery [sourceCards]="userDataService.reviewAndPublishedCards"  (cardClicked)="onPublishReviewRejectModelsCardClicked($event)"  class="zero-margin last-slider-no-switch-panel" ></app-thumbnail-gallery>
  </ng-container>
    <ng-container *ngIf="webNavService.currentWebSwitchPanelMode === SWITCH_PANEL_ITEM.REJECTED">
      <app-thumbnail-gallery [sourceCards]="userDataService.rejectedUserCards"   (cardClicked)="onPublishReviewRejectModelsCardClicked($event)"  class="zero-margin last-slider-no-switch-panel" ></app-thumbnail-gallery>
  </ng-container>
</div>
</ng-container>

<app-first-use-adv [@inOutAnimationFast] *ngIf="(!webNavService.isUserAuthenticated && !settingService.getOriginalUidFromLocalstorage()) || (isNoDataShown() && userDataService.savedCollections?.length === 0)"></app-first-use-adv>


<app-fullsize-dragdrop *ngIf="webNavService.isUserAuthenticated"></app-fullsize-dragdrop>
<app-web-models-card @modelFeedAnimation #modelCardsFeed class="feed-overlap model-feed" *ngIf="isModelFeedShown" [startPosition]="cardFeedCardStartPosition" [feedIsVisible]="isModelFeedShown" [cardsRaw]="modelCardsMock" [feedMode]="cardFeedMode" [feedModificator]="webLibraryFeedModificator" (cardPressed)="onModelCardPressed()" (cardClicked)="onModelCardClicked($event)" (actionBtnClicked)="onCardControlBtnClicked($event)"></app-web-models-card>
