<div class="header-bar" [ngClass]="{'hasButtons' : hasButtonsInHeader() }" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="header-left" (click)="titleClick($event)"><h1 *ngIf="galleryTitle">{{galleryTitle}}</h1></div>
  <div class="header-right" fxLayout="row">
  </div>
</div>

<div *ngIf="sourceCards" class="gallery-thumb-wrapper" [ngClass]="getThumbWrapperCombinedClass()" [appResponsiveClass]="'single-gallery'" fxLayout="row wrap">
  <ng-content #uploadCard></ng-content>
  <div class="gallery-thumb sourceCards" [ngClass]="getCombinedClass(sourceCard)"  (click)="onThumbCardClickWithDisableCheck(sourceCard)" *ngFor="let sourceCard of sourceCards | sortByDate, let thumbIndex = index" [attr.disabled]="sourceCard?.isDisabled" [ngStyle]="{'background-image': 'url(' + cardPosterOrMockImage(sourceCard?.cardPosterMobile, sourceCard) + ')'}">
    <mat-icon *ngIf="galleryMode === GALLERY_MODE.SELECTION && sourceCard?.isSelected || galleryMode === GALLERY_MODE.SELECTION && isCardInDisabledArray(sourceCard)" [@inOutAnimation] class="select-checkbox" svgIcon="checkbox-icon"></mat-icon>
    <div *ngIf="settingService.showDateModified" class="date-cover">{{sourceCard.modifiedDate | date:'medium'}}</div>
    <div *ngIf="showCardPrice && sourceCard?.cardPrice" class="price-cover" (click)="onPriceTagClicked($event, sourceCard)" [ngClass]="{'selected' : sourceCard?.isSelected}">
      ${{sourceCard?.cardPrice}}.00
    </div>
  </div>
</div>

<div *ngIf="sourceCollections && galleryMode === GALLERY_MODE.COLLECTIONS_NON_COMPOSITE" class="gallery-thumb-wrapper" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
  <div class="gallery-thumb thumb-collections-non-composite" fxFlex="0 1 calc(33.3334% - 6.33px)" fxLayout="column" fxLayoutAlign="start center"
  (click)="onThumbCollectionClick(collection)"   *ngFor="let collection of sourceCollections, let thumbIndex = index" [ngStyle]="{'background-image': 'url(' + processcardPosterMobile(collection.collectionCards[0]?.cardPosterMobile, collection.collectionPosterMobile) + ')'}">
  </div>
</div>


<!-- NOT AGGREGATED -->
<div *ngIf="sourceCollections && (galleryMode === GALLERY_MODE.DEFAULT || galleryMode === GALLERY_MODE.SELECTION)" class="gallery-thumb-wrapper-composite" [ngClass]="getCompositeClass()"  [appResponsiveClass]="'gallery'"  fxLayout="row wrap" >

  <!-- LIBRARY: ALL MODELS -->
  <ng-container *ngIf="notInCollection?.collectionCards?.length > 0 || savedCardCollection?.length > 0 || uploadService?.uploadModels?.length > 0">
    <div class="gallery-thumb-composite" [ngClass]="getCompositeClass()"  (click)="onDefaultCollectionThumbClick()"    fxLayout="column"  >
      <app-thumb-composite class="top-row"  fxlayout="column" compositeMode="allModels" [notInCollection]="notInCollection" (compositeThumbClicked)="onDefaultCollectionThumbClick()"></app-thumb-composite>
      <div class="bottom-row first-row" fxFlex="1 1 24px" >
          <div class="bottom-title"><span>All Models</span></div>
      </div>
      <div class="bottom-row" fxFlex="1 1 20px" fxlayout="column">
        <div class="bottom-count">{{notInCollection?.collectionCards?.length + getNumberOfUploadingCardsForCollection(undefined)}}  <ng-container *ngIf="ifCollectionHasAnyCardsInUploadingState(undefined)"> • Uploading</ng-container></div>
      </div>
    </div>
  </ng-container>

  <!-- LIBRARY: MAIN PAGE USER DRAFT / SAVED COLLECTIONS  -->
   <ng-container title="saved/draft collections" >
  <ng-container *ngFor="let collection of mergedCollections | sortByDate:'updated', let thumbIndex = index">
    <div class="gallery-thumb-composite" [ngClass]="getCompositeClass()" (click)="onThumbCollectionClick(collection)" fxLayout="column">
      <mat-icon *ngIf="galleryMode === GALLERY_MODE.SELECTION && collection.isSelected" [@inOutAnimation] class="select-checkbox" svgIcon="checkbox-icon"></mat-icon>
      <app-thumb-composite class="top-row" fxlayout="column" compositeMode="collection" [collection]="collection" (compositeThumbClicked)="onThumbCollectionClick(collection)"></app-thumb-composite>
      <div class="bottom-row first-row" fxFlex="1 1 24px">
          <div class="bottom-title" [ngClass]="{'collaborative': collection?.accessType === 'Collaborative'}">
              <span>{{collection?.collectionTitle}}</span>
          </div>
      </div>
      <div class="bottom-row" fxFlex="1 1 20px" fxlayout="column">
        <div class="bottom-count">
          {{collection?.collectionCards?.length + getNumberOfUploadingCardsForCollection(collection)}}
          <ng-container *ngIf="ifCollectionHasAnyCardsInUploadingState(collection)"> • Uploading</ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

</div>

<!-- PORTFOLIO PURCHASED COLLECTIONS -->
<div *ngIf="sourceCollections && galleryMode === GALLERY_MODE.AGGREGATED_STATIC" class="gallery-thumb-wrapper-composite" fxLayout="row wrap" fxLayoutAlign="space-between" >
  <ng-container *ngFor="let collection of sourceCollections, let thumbIndex = index">
    <div class="gallery-thumb-composite" [ngClass]="getCompositeClass()"  (click)="onThumbCollectionClick(collection)"   fxLayout="column"  >
      <app-thumb-composite class="top-row"  fxlayout="column" compositeMode="aggregated-static" [collection]="collection" (compositeThumbClicked)="onThumbCollectionClick(collection)"></app-thumb-composite>
      <div class="bottom-row first-row" fxFlex="1 1 24px" >
          <div class="bottom-title" [ngClass]="{'collaborative': collection?.accessType === 'Collaborative'}"><span>{{collection?.collectionTitle}}</span></div>
      </div>
      <div class="bottom-row" fxFlex="1 1 20px" fxlayout="column">
        <div class="bottom-count">{{collection?.collectionCards?.length}}</div>
      </div>
    </div>
  </ng-container>
</div>
